export enum AccountingBlobType {
  Miscellaneous = 0,
  Invoice = 1,
  InvoicePdf = 2,
  InvoiceImportSummary = 3,
  InvoiceDetail = 4,
}

export type AccountingInvoiceQueryProperties = {
  timestamp: Date | null;
  blobType: AccountingBlobType;
};

export const defaultAccountingInvoiceQueryProperties = {
  timestamp: new Date(),
  blobType: AccountingBlobType.Invoice,
} as AccountingInvoiceQueryProperties;

export type AccountingInvoiceDescription = {
  year: string;
  month: string;
  masAccountID: number;
  salesOrderID: number;
  masAccountName: string;
  blobName: string;
  blobUrl: string;
  invoiceNumber: string;
};
